/** @jsx jsx */

import React from 'react';
import { jsx, css } from '@emotion/core';
import Page from 'orbiter-web-components/src/components/page/Page';
import DoubleMenu from 'orbiter-web-components/src/components/topbar/DoubleMenu';
import Theme, { applyTheme } from 'orbiter-web-components/src/components/Theme';
import { ThemeProvider } from 'emotion-theming';
import { ProjectTheme } from '../Theme';
import SimpleAppointment from 'orbiter-web-components/src/components/appointment/SimpleAppointment';
import ManualGrid from 'orbiter-web-components/src/components/grid/ManualGrid';
import { Container } from 'react-bootstrap';
import Footer from 'orbiter-web-components/src/components/footer/Footer';
import moment from 'moment';
import Fade from 'react-reveal/Fade';
import TopBar from '../components/TopBar';

export const defaultStyling = (theme: Theme) => {
    return css`
        color: ${applyTheme(theme, (t: Theme) => t.colors.primaryText)};
        line-height: ${applyTheme(theme, (t: Theme) => t.font.lineHeight.default)};
        font-family: ${applyTheme(theme, (t: Theme) => t.font.fontFamily.default)};
        font-size: ${applyTheme(theme, (t: Theme) => t.font.fontSize.default)};
    `;
};

export const homePageCardStyling = (theme: Theme) => {
    return css`
        border: none !important;
        margin: 0 !important;
        // height: 100vh; 
        background-color: transparent !important;
    `;
};

export const noPaddingStyling = (theme: Theme) => {
    return css`
        padding: 0 !important;
    `;
};

export const contentContainerStyling = (theme: Theme) => {
    return css`
        text-align: left;
        margin-left: 20%;
        margin-right: 20%;
        @media (max-width: ${applyTheme(theme, (t: Theme) => t.mobileWidth.minWidth)} )  {
            text-align: left;
        }
    `;
};

export const smallMargin = (theme: Theme) => {
    return css`
        margin: ${applyTheme(theme, (t: Theme) => t.margin.extraSmallMargin)};
    `;
};

export const CDStyling = (theme: Theme) => {
    return css`
        margin: 0 auto; 
        @media (max-width: ${applyTheme(theme, (t: Theme) => t.mobileWidth.minWidth)} )  {
            margin-top: 20%;
        }
    `;
};

export const pageStyling = (theme: Theme) => {
    return css`
        margin-top: 150px;
        @media (max-width: ${applyTheme(theme, (t: Theme) => t.mobileWidth.minWidth)} )  {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    `;
};

export const titleStyling = (theme: Theme) => {
    return css`
        margin-bottom: 30px;
        margin-top: 30px;
        @media (max-width: ${applyTheme(theme, (t: Theme) => t.mobileWidth.minWidth)} )  {
            margin-left: 10px;
            
        }
    `;
};

export const backgroundColorStyleFirst = (theme: Theme) => {
    return css`
        ${backgroundColorStyleRegular(theme)}
        background-color: #F4F4F4;
    `;
};

export const backgroundColorStyleRegular = (theme: Theme) => {
    return css`
    transition: all .2s ease-in-out;
    &:hover{
        background-color: #383838;
        color: white;
        transform: scale(1.05);
    }
    `;
};

class Kalender extends React.Component<any, any> {
    private static DELAY = 100;

    constructor(_: any){
        super(_);
        this.state = {
            latestIndexVisible: 0,
        }
    }

    public render() {

        let delay = 0;

        const renderConcert = (c: any, index: number) => {
            const id = c._id;
            const name = c.name;
            const date = moment(c.date).utc(false);
            let url = c.url;
            if(url === ""){
                url = undefined;
            }
            let time = date.format("H:m");
            if(time === "0:0"){
                time = "";
            }
            if(index > this.state.latestIndexVisible){
                delay += Kalender.DELAY;
            }
            return  <Fade key={id} duration={1000} delay={delay} left={true} wait={delay} onReveal={() => {
                if(index > this.state.latestIndexVisible){this.setState({latestIndexVisible: index})}
                }}>
                        <SimpleAppointment url={url} date={date.format("DD/MM/YYYY")} time={date.format("H:mm")} appointmentType="Concert" appointmentStyle={index === 0 ? backgroundColorStyleFirst : backgroundColorStyleRegular}>
                            {name}
                        </SimpleAppointment>
                    </Fade>;
        };

        const futureConcerts = this.props.futureConcerts;
        const futureConcertsRender = futureConcerts?.map(renderConcert)
        
        const pastConcerts = this.props.pastConcerts;
        const pastConcertsRender = pastConcerts?.map(renderConcert)

        return (
            <ThemeProvider theme={ProjectTheme}>
                    <Page menu={<TopBar/>}
                        pageStyling={defaultStyling}
                        footer={<Footer/>}>
                            <Container fluid={false} css={pageStyling}>
                                <h1 css={titleStyling} >Opkomende Concerten</h1>
                                {(()=>{
                                    if(!futureConcerts || futureConcerts.length === 0){
                                        return <p css={css`margin: 10px;`}><i>Er zijn momenteel geen concerten gekend.</i></p>;
                                    }else{
                                        return  <ManualGrid cols={3} gridItemContainerStyling={(t: Theme) => css`vertical-align: top;`}>
                                                    {futureConcertsRender}
                                                </ManualGrid>;
                                    }
                                })()}

                                <h1 css={titleStyling} >Gepasseerde Concerten</h1>
                                {(()=>{
                                    if(!pastConcerts || pastConcerts.length === 0){
                                        return <p css={css`margin: 10px;`}><i>Er zijn momenteel geen concerten gekend.</i></p>;
                                    }else{
                                        return  <ManualGrid cols={3} gridItemContainerStyling={(t: Theme) => css`vertical-align: top;`}>
                                                    {pastConcertsRender}
                                                </ManualGrid>;
                                    }
                                })()}
                            </Container>
                    </Page>
            </ThemeProvider>

        );
    }
}

export default Kalender;
