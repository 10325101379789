/** @jsx jsx */

import React from 'react';
import { jsx, css } from '@emotion/core';
import Page from 'orbiter-web-components/src/components/page/Page';
import Theme, { applyTheme } from 'orbiter-web-components/src/components/Theme';
import LeftCard from 'orbiter-web-components/src/components/card/LeftCard';
import { ThemeProvider } from 'emotion-theming';
import { ProjectTheme } from '../Theme';
import TwoSectionedFullScreenImage from 'orbiter-web-components/src/components/fullScreenImage/TwoSectionedFullScreenImage';
import AnimatedButton from 'orbiter-web-components/src/components/button/AnimatedButton';
import Footer from 'orbiter-web-components/src/components/footer/Footer';
import TopBar from '../components/TopBar';
import Fade from 'react-reveal/Fade';

export const defaultStyling = (theme: Theme) => {
    return css`
        color: ${applyTheme(theme, (t: Theme) => t.colors.primaryText)};
        line-height: ${applyTheme(theme, (t: Theme) => t.font.lineHeight.default)};
        font-family: ${applyTheme(theme, (t: Theme) => t.font.fontFamily.default)};
        font-size: ${applyTheme(theme, (t: Theme) => t.font.fontSize.default)};
    `;
};

export const homePageCardStyling = (theme: Theme) => {
    return css`
        border: none !important;
        margin: 0 !important;
        // height: 100vh; 
        background-color: transparent !important;
    `;
};

export const noPaddingStyling = (theme: Theme) => {
    return css`
        padding: 0 !important;
    `;
};

export const contentContainerStyling = (theme: Theme) => {
    return css`
        text-align: left;
        margin-left: 20%;
        margin-right: 5%;
        @media (max-width: ${applyTheme(theme, (t: Theme) => t.mobileWidth.minWidth)} )  {
            text-align: left;
            margin-left: 0;
            margin-right: 0;
        }

        @media (max-width: 1200px )  {
            text-align: left;
            margin-left: 10%;
            margin-right: 10%;
        }
    `;
};

export const CDStyling = (theme: Theme) => {
    return css`
        margin: 0 auto; 
        @media (max-width: ${applyTheme(theme, (t: Theme) => t.mobileWidth.minWidth)} )  {
            margin-top: 100px;
        }
    `;
};

class Biografie extends React.Component<any, any> {

    constructor(a: any) {
        super(a);
        this.state = {
        };
    }

    public render() {
        return (
            <ThemeProvider theme={ProjectTheme}>
                <Page menu={<TopBar />}
                    pageStyling={defaultStyling}
                    footer={<Footer />}>

                    <TwoSectionedFullScreenImage
                        leftUnder={true}
                        leftSide={
                            <Fade duration={1500} left={true} delay={200}>
                                <LeftCard contentContainerStyle={contentContainerStyling} verticalAlignContentStyle={noPaddingStyling} cardStyle={homePageCardStyling} title="Biografie">
                                    <p>
                                        Cedric Honings (°1999) kreeg aan de Kunstacademie Noord-Limburg zijn eerste gitaarlessen
                                        van Tom Van den Eynde, vanaf zijn elfde volgde hij gitaarlessen bij Karel Ooms en zijn laatste
                                        jaar bij Willem-Jan Lauwers. Hij volgde er tevens muziekschriftuur, slagwerk- en pianolessen.
                                        Sinds september 2014 kreeg hij ook gitaarlessen aan het Conservatorium van Maastricht in de
                                        Jong Talentklas bij Roman Krejci. Vanaf september 2016 volgde hij met een creditcontract les
                                        aan het Koninklijk Conservatorium te Brussel in de gitaarklas van Antigoni Goni en
                                        muziekschriftuur in de klas van Wouter Lenaerts. Cedric zet zijn beide studies in Brussel nu
                                        verder. Ook mocht hij het podium al delen met zijn 2 hoofdprofessoren in solorecitals en
                                        concerto’s.
                                        </p>
                                </LeftCard>
                            </Fade>
                        }
                        rightSide={
                            <Fade duration={1500} right={true}>
                                <LeftCard imageSrc="images/fotos/13.jpg" ImageColSize={10} imageContainerStyle={CDStyling} cardStyle={homePageCardStyling} />
                            </Fade>
                        }
                        leftRatio={0.6}
                    />
                    <TwoSectionedFullScreenImage
                        leftUnder={false}
                        leftSide={
                            <Fade duration={1500} left={true}>
                                <LeftCard imageSrc="images/fotos/6L6A3574.jpg" ImageColSize={10} imageContainerStyle={CDStyling} cardStyle={homePageCardStyling} />
                            </Fade>
                        }
                        rightSide={
                            <Fade duration={1500} right={true} delay={200}>
                                <LeftCard contentContainerStyle={contentContainerStyling} verticalAlignContentStyle={noPaddingStyling} cardStyle={homePageCardStyling} title="">
                                    <p>
                                        Dat hij talent heeft is overduidelijk, want sinds zijn eerste gitaarlessen legde hij een
                                        indrukwekkend muzikaal parcours af langs tal van concerten, behaalde hij verschillende
                                        prestigieuze prijzen en studiebeurzen (International Summer Academy Mozarteum Salzburg,
                                        Musíca en Compostela, STROOM, Cultuur Erfgoedproject...), gaf hij solorecitals en trad als
                                        solist op met verschillende orkesten (SymphoniaASSAI, LOJM, La Passione, Mechels
                                        Kamerorkest, KHP...) in o.a. Verenigde Staten, Polen, Duitsland, Italië, Frankrijk, Luxemburg,
                                        Spanje, Oostenrijk, België en Nederland. Onlangs gaf hij nog een solorecital in een uitverkocht
                                        concert in BOZAR, Brussel. Hij werkt regelmatig mee aan diverse projecten; zo trad hij op
                                        tijdens voorstellingen van choreografe Isabelle Beernaert in het Koninklijk Theater Carré,
                                        Amsterdam. Hij vormt daarnaast met fluitiste Lindsay Gijbels ‘Duo Komorebi’ en met
                                        beiaardier Jan Verheyen ‘Guitarillon’. Ook met zangeres Indirah brengt hij verrassende
                                        concerten met een mix van klassieke muziek en pop en rock.
                                        </p>

                                </LeftCard>
                            </Fade>
                        }
                        leftRatio={0.4}
                    />
                    <TwoSectionedFullScreenImage
                        leftUnder={true}
                        leftSide={
                            <Fade duration={1500} left={true} delay={200}>
                                <LeftCard contentContainerStyle={contentContainerStyling} verticalAlignContentStyle={noPaddingStyling} cardStyle={homePageCardStyling} title="">
                                    <p>
                                        Ondanks zijn jeugdige leeftijd, behaalde hij tijdens het Prinses Christina Concours te Maastricht
                                        in 2012 een aanmoedigingsprijs, in 2013 een eervolle vermelding om dan in 2014 zowel de
                                        eerste prijs als de publieksprijs van deze wedstrijd te winnen. In 2013 wint hij de ‘Nationale
                                        Wedstrijd Nicolas Alfonso’ tijdens het Brussels International Guitar Festival en in 2014 de
                                        eerste prijs op het Antwerpen Gitaarfestival. In 2016 werd hij geselecteerd om met het LOJM
                                        op te treden en in mei 2018 behaalde hij de tweede prijs op het International Gevelsberg
                                        Gitarrenfestival.
                                        </p>

                                </LeftCard>
                            </Fade>
                        }
                        rightSide={

                            <Fade duration={1500} right={true}>
                                <LeftCard imageSrc="images/fotos/6L6A3546.jpg" ImageColSize={10} imageContainerStyle={CDStyling} cardStyle={homePageCardStyling} />
                            </Fade>
                        }
                        leftRatio={0.6}
                    />
                    <TwoSectionedFullScreenImage
                        leftUnder={false}
                        leftSide={
                            <Fade duration={1500} left={true}>
                                <LeftCard imageSrc="images/fotos/6L6A3590.jpg" ImageColSize={10} imageContainerStyle={CDStyling} cardStyle={homePageCardStyling} />
                            </Fade>
                        }
                        rightSide={
                            <Fade duration={1500} right={true} delay={200}>
                                <LeftCard contentContainerStyle={contentContainerStyling} verticalAlignContentStyle={noPaddingStyling} cardStyle={homePageCardStyling} title="">
                                    <p>
                                        Cedric heeft een imposante lijst van masterclasses op zijn naam staan; bij Pepe Romero,
                                        Ricardo Gallén, Zoran Dukić, Judicaël Perroy, Roberto Aussel, Odaïr Assad, Jan Depreter,
                                        Carlo Marchione, Tilman Hoppstock, Eliot Fisk, Zaïra Meneses, Roman Viazovskiy, Irina
                                        Kulikova, Matthew Mc Allister, Chris Stell, Mark Eden, Oman Kaminsky, Fernando Espi,
                                        Thomas Müller-Pering, Nejc Kuhar, Kostas Tosidis, Michalis Sourvinos, José Luis Rodrigo,
                                        Raphaëlla Smits, Allan Neave, Laura Snowden, Daniela Rossi, Stephanie Jones, Hans Willhem
                                        Kaufmann, Hans-Werner Huppertz en vele anderen. In 2015 nam Cedric zijn eerste CD in
                                        Londen op. De internationale gerenommeerde gitarist en componist Roland Chadwick uit
                                        Engeland vertrouwde zijn nieuw muziekstuk ‘Tiny Wooden Gods’ aan Cedric toe. Het feit dat
                                        deze topgitarist met Cedric wou samenwerken is niet alleen een grote eer, maar vooral een
                                        bevestiging van het talent uit het Limburgse Peer. Cedric bracht ook zijn eerste single uit
                                        genaamd ‘Song of Hope’. Song of Hope is een transcriptie van de hand van Cedric van de
                                        stadshymne uit ‘Symphony No.1’ van Kevin Houben. Ook zijn opnames vielen op voor

                                        radioicoon Marc Brillouet. Zo verscheen hij op de laatste CD van Brillouet ‘Funiculi Funicula
                                        Vol. 6’.
                                        </p>

                                </LeftCard>
                            </Fade>
                        }
                        leftRatio={0.4}
                    />
                </Page>
            </ThemeProvider>

        );
    }
}

export default Biografie;