/** @jsx jsx */
import * as React from 'react';
import { jsx, css, SerializedStyles, Interpolation } from '@emotion/core';
import Theme from 'orbiter-web-components/src/components/Theme';
import Button from 'orbiter-web-components/src/components/button/Button';
import { Image } from 'react-bootstrap';
import { buttonStyling, innerButtonStyling, imageContainerStyling, inlineStyling, textContainerStyling } from './MainButtonStyling';

export interface FileButtonProps {
    fileIcon: string;
    fileName: string;
    fileDownloadLink: string;

    slideShowStyle?: (theme: Theme) => Interpolation;
    titleStyle?: (theme: Theme) => Interpolation;
}

export default class FileButton extends React.Component<FileButtonProps, any> {
    constructor(props: Readonly<FileButtonProps>) {
        super(props);

    } 

    public render() {
        return (
            <Button buttonStyle={buttonStyling} href={this.props.fileDownloadLink}>
                <div css={innerButtonStyling}>
                    <div css={imageContainerStyling}>
                        <Image css={inlineStyling} src={this.props.fileIcon}/>
                    </div>
                    <div css={textContainerStyling}>
                        <p css={inlineStyling}>{this.props.fileName}</p>
                    </div>
                </div>
            </Button>
        );
    }
}