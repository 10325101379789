/** @jsx jsx */
import * as React from 'react';
import { jsx, css, SerializedStyles, Interpolation } from '@emotion/core';
import Theme, { applyTheme } from 'orbiter-web-components/src/components/Theme';
import UnstyledLink from 'orbiter-web-components/src/components/link/UnstyledLink';

export const buttonStyling = (theme: Theme) => {
    return css`
        border: none;
        height: 100%;
        text-align: left;
        width: 100%;
        padding: 10px 10px 10px 10px;
        cursor: pointer;
        @media (max-width: ${applyTheme(theme, (t: Theme) => t.mobileWidth.minWidth)} )  {
            padding: 20px;
        }
        min-height: 150px;
    `;
};

export const titleTextStyling = (theme: Theme) => {
    return css`
        margin: 0;
        @media (max-width: ${applyTheme(theme, (t: Theme) => t.mobileWidth.minWidth)} )  {
            text-decoration: underline;
        }
        
    `;
};

export const dateTextStyling = (theme: Theme) => {
    return css`
        margin: 0;
        color: #7b7878;
    `;
};

export interface ArticleButtonProps {
    articleTitle: string;
    articleDate: string;
    siteLink: string;

    onClick?: (e: React.MouseEvent) => void;

    buttonStyle?: (theme: Theme) => Interpolation;
    dateTextStyle?: (theme: Theme) => Interpolation;
    titleTextStyle?: (theme: Theme) => Interpolation;
}

export default class ArticleButton extends React.Component<ArticleButtonProps, any> {
    constructor(props: Readonly<ArticleButtonProps>) {
        super(props);
    } 

    public render() {
        return (
            <UnstyledLink href={this.props.siteLink}>
                <div css={(t: Theme) => [buttonStyling(t), this.props.buttonStyle !== undefined ? this.props.buttonStyle(t) : undefined]}>
                    <p css={(t: Theme) => [titleTextStyling(t), this.props.titleTextStyle !== undefined ? this.props.titleTextStyle(t) : undefined]}>{this.props.articleTitle}</p>
                    <p css={(t: Theme) => [dateTextStyling(t), this.props.dateTextStyle !== undefined ? this.props.dateTextStyle(t) : undefined]}>{this.props.articleDate}</p>
                </div>
            </UnstyledLink>
        );
    }
}