/** @jsx jsx */

import React from 'react';
import { jsx, css } from '@emotion/core';
import Page from 'orbiter-web-components/src/components/page/Page';
import DoubleMenu from 'orbiter-web-components/src/components/topbar/DoubleMenu';
import Theme, { applyTheme } from 'orbiter-web-components/src/components/Theme';
import LeftCard from 'orbiter-web-components/src/components/card/LeftCard';
import TwoSectionedFullScreenImage from 'orbiter-web-components/src/components/fullScreenImage/TwoSectionedFullScreenImage';
import { ThemeProvider, withTheme } from 'emotion-theming';
import { ProjectTheme } from '../Theme';
import Footer from 'orbiter-web-components/src/components/footer/Footer';
import Fade from 'react-reveal/Fade';
import TopBar from '../components/TopBar';

export const defaultStyling = (theme: Theme) => {
    return css`
        color: ${applyTheme(theme, (t: Theme) => t.colors.primaryText)};
        line-height: ${applyTheme(theme, (t: Theme) => t.font.lineHeight.default)};
        font-family: ${applyTheme(theme, (t: Theme) => t.font.fontFamily.default)};
        font-size: ${applyTheme(theme, (t: Theme) => t.font.fontSize.default)};
    `;
};

export const homePageCardStyling = (theme: Theme) => {
    return css`
        border: none !important;
        margin: 0 !important;
        background-color: transparent !important;
        @media (max-width: ${applyTheme(theme, (t: Theme) => t.mobileWidth.minWidth)} )  {
            margin-top: 300px !important;
        }
    `;
};

export const noPaddingStyling = (theme: Theme) => {
    return css`
        padding: 0 !important;
    `;
};

export const fullHeightStyling = (theme: Theme) => {
    return css`
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
    `;
};

export const contentContainerStyling = (theme: Theme) => {
    return css`
        text-align: right;
        margin-right: 20%;
        @media (max-width: ${applyTheme(theme, (t: Theme) => t.mobileWidth.minWidth)} )  {
            text-align: left;
            margin-right: auto;
            background: linear-gradient(rgba(255,255,255,0.0), rgba(255,255,255,0.95));
        }
    `;
};

export const backgroundStyling = (theme: Theme) => {
    return css`
        margin-right: 40%;
        box-shadow: inset -200px 0 100px #fff;
        @media (max-width: ${applyTheme(theme, (t: Theme) => t.mobileWidth.minWidth)} )  {
            margin-right: 0;
            box-shadow: none;
        }
    `;
};

class MijnWerk extends React.Component<any, any> {

    constructor(a: any) {
        super(a);
        this.state = {
        };
    }

    public render() {
        return (
            <ThemeProvider theme={ProjectTheme}>
                <Page menu={<TopBar/>}
                    pageStyling={defaultStyling}
                    footer={<Footer/>}
                    >
                    <TwoSectionedFullScreenImage
                        backgroundImage="images/fotos/homeImageGradient.png"
                        backgroundStyling={backgroundStyling}
                        leftUnder={false}
                        rightSide={
                            <Fade duration={1500} right={true} delay={200}>
                                <LeftCard contentContainerStyle={contentContainerStyling} verticalAlignContentStyle={noPaddingStyling} cardStyle={homePageCardStyling} title="Music is what feelings sound like." linkTitle="Meer informatie" href={"/mijn-werk"} onClick={() => console.log('meer info home')}>
                                    <p>
                                        Cedric Honings begon als negenjarige met notenleer. Tien jaar later is hij niet meer aan zijn proefstuk toe. <br/>
                                        Dat hij talent heeft, is overduidelijk want sinds die eerste gitaarlessen legde hij een indrukwekkend muzikaal parcours af langs tal van concerten en behaalde hij verschillende prestigieuze prijzen.
                                    </p>
                                </LeftCard>
                            </Fade>
                        }
                    />
                </Page>
            </ThemeProvider>

        );
    }
}

export default MijnWerk;
