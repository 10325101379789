import Home from './pages/Home';
import MijnWerk from './pages/MijnWerk';
import Kalender from './pages/Kalender';
import Media from './pages/Media';
import Biografie from './pages/Biografie';
// import C from './C';

const routes = [
    {
        path: '/',
        component: Home,
        name: 'Home',
        exact: true,
    },
    {
        path: '/mijn-werk',
        component: MijnWerk,
        name: 'mijn werk',
        exact: true,
    },
    {
        path: '/kalender',
        component: Kalender,
        name: 'kalender',
        exact: true,
    },
    {
        path: '/media',
        component: Media,
        name: 'media',
        exact: true,
    },
    {
        path: '/biografie',
        component: Biografie,
        name: 'biografie',
        exact: true,
    },
];
  
export default routes as any;