import { jsx, css, SerializedStyles, Interpolation } from '@emotion/core';
import Theme from 'orbiter-web-components/src/components/Theme';

export const inlineStyling = (theme: Theme) => {
    return css`
        height: 82px;
        display: inline;
        padding: 5px;
    `;
};

export const imageStyling = (theme: Theme) => {
    return css`
        display: inline;
        padding: 5px;
        float: left;
        height: 60px;
    `;
};

export const innerButtonStyling = (theme: Theme) => {
    return css`
        padding: 10px;
        display: flex;
        align-items: center;
    `;
};

export const imageContainerStyling = (theme: Theme) => {
    return css`
        display: inline-block;
    `;
};

export const textContainerStyling = (theme: Theme) => {
    return css`
        display: inline-block;
    `;
};

export const buttonStyling = (theme: Theme) => {
    return css`
        width: 100%;
        margin: 10px;
        @media (max-width: 500px )  {
            margin: 0px;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        transition: all .2s ease-in-out;
        &:hover{
            transform: scale(1.05);
        }
    `;
};