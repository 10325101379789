/** @jsx jsx */

import React from 'react';
import { jsx, css } from '@emotion/core';
import Page from 'orbiter-web-components/src/components/page/Page';
import ImageSlideShow from 'orbiter-web-components/src/components/imageSlideShow/ImageSlideShow';
import Footer from 'orbiter-web-components/src/components/footer/Footer';
import Theme, { applyTheme } from 'orbiter-web-components/src/components/Theme';
import { ThemeProvider } from 'emotion-theming';
import { ProjectTheme } from '../Theme';
import { Container, Col, Row } from 'react-bootstrap';
import FileButton from '../components/FileButton';
import ArticleButton from '../components/ArticleButton';
import ContactButton from '../components/ContactButton';
import moment from 'moment';
import Fade from 'react-reveal/Fade';
import ManualGrid from 'orbiter-web-components/src/components/grid/ManualGrid';
import TopBar from '../components/TopBar';

export const defaultStyling = (theme: Theme) => {
    return css`
        color: ${applyTheme(theme, (t: Theme) => t.colors.primaryText)};
        line-height: ${applyTheme(theme, (t: Theme) => t.font.lineHeight.default)};
        font-family: ${applyTheme(theme, (t: Theme) => t.font.fontFamily.default)};
        font-size: ${applyTheme(theme, (t: Theme) => t.font.fontSize.default)};
    `;
};

export const homePageCardStyling = (theme: Theme) => {
    return css`
        border: none !important;
        margin: 0 !important;
        // height: 100vh; 
        background-color: transparent !important;
    `;
};

export const noPaddingStyling = (theme: Theme) => {
    return css`
        padding: 0 !important;
    `;
};

export const contentContainerStyling = (theme: Theme) => {
    return css`
        text-align: left;
        margin-left: 20%;
        margin-right: 20%;
        @media (max-width: ${applyTheme(theme, (t: Theme) => t.mobileWidth.minWidth)} )  {
            text-align: left;
        }
    `;
};

export const smallMargin = (theme: Theme) => {
    return css`
        margin: ${applyTheme(theme, (t: Theme) => t.margin.extraSmallMargin)};
    `;
};

export const smallPadding = (theme: Theme) => {
    return css`
        padding: ${applyTheme(theme, (t: Theme) => t.padding.smallPadding)};
        min-height: 170px;
    `;
};

export const CDStyling = (theme: Theme) => {
    return css`
        margin: 0 auto; 
        @media (max-width: ${applyTheme(theme, (t: Theme) => t.mobileWidth.minWidth)} )  {
            margin-top: 20%;
        }
    `;
};

export const pageStyling = (theme: Theme) => {
    return css`
        margin-top: 150px;
    `;
};

export const titleStyling = (theme: Theme) => {
    return css`
        margin-bottom: 30px;
        margin-top: 30px;
    `;
};

export const backgroundColorStyle = (theme: Theme) => {
    return css`
        background-color: #F4F4F4;
    `;
};
export const h2Styling = (theme: Theme) => {
    return css`
        margin-top: 30px;
    `;
};

export const noSpaceStyling = (theme: Theme) => {
    return css`
        margin: 0px !important;
        padding: 0px !important;
    `;
};

class Media extends React.Component<any, any> {
    private static DELAY = 100;
    private scrollMenuCollorRef: React.RefObject<HTMLDivElement>;

    constructor(a: any) {
        super(a);
        this.scrollMenuCollorRef = React.createRef();
        this.state = {
            latestIndexVisible: 0,
        }
    }

    public render() {

        let delay = 0;

        const renderArticle = (a: any, index: number) => {
            const id = a._id;
            const name = a.name;
            const date = moment(a.date);
            const url = a.url;
            if(index > this.state.latestIndexVisible){
                delay += Media.DELAY;
            }
            return  <Fade key={id} duration={1000} delay={delay} left={true} wait={delay} onReveal={() => {
                if(index > this.state.latestIndexVisible){this.setState({latestIndexVisible: index})}
                }}>
                        <ArticleButton articleDate={date.format("DD/MM/YYYY")} articleTitle={name} siteLink={url} buttonStyle={(theme: Theme) => css`
                        transition: all .2s ease-in-out;
                        &:hover{
                            background-color: #383838;
                            color: white;
                            transform: scale(1.05);
                        }
                        `} />
                    </Fade>;
        };

        const articles = this.props.articles;
        const articlesRender = articles?.map(renderArticle)

        return (
            <ThemeProvider theme={ProjectTheme}>
                <Page menu={<TopBar/>}
                    pageStyling={defaultStyling}
                    footer={<Footer />}>

                    <Container css={(theme: Theme) => css`margin-top: 120px;`}>
                        <h1>Contact info</h1>
                        <Row>
                            <Col xl={4}>
                                <ContactButton contactLink="mailto:cedrichonings@outlook.com" visualContactInfo="cedrichonings@outlook.com" linkImage="images/icons/email.svg" />
                            </Col>
                            <Col xl={4}>
                                <ContactButton contactLink="https://www.youtube.com/channel/UCvdN1yKowzjYUUcIlt-Rxjg/featured" visualContactInfo="@CedricHonings" linkImage="images/icons/logo-youtube-black.svg" />
                            </Col>
                            <Col xl={4}>
                                <ContactButton contactLink="https://www.instagram.com/cedrichoningsguitar/?hl=nl" visualContactInfo="@cedrichoningsguitar" linkImage="images/icons/logo-instagram-black.svg" />
                            </Col>
                            <Col xl={4}>
                                <ContactButton contactLink="https://www.facebook.com/CedricHoningsGuitarist/" visualContactInfo="@CedricHoningsGuitarist" linkImage="images/icons/logo-facebook-black.svg" />
                            </Col>
                        </Row>

                        <h1 css={h2Styling}>Press kit</h1>
                        <h2 css={h2Styling}>Curriculum vitae</h2>
                        <Row>

                            <Col xl={4}>
                                <FileButton fileIcon="images/icons/file-pdf.svg" fileName="Portable Document Format (PDF)" fileDownloadLink="/files/CV-Cedric-Honings-2020.pdf" />
                            </Col>

                        </Row>

                        <h2 css={h2Styling}>Persfoto's</h2>
                        <Row>
                            <Col xl={4}>
                                <FileButton fileIcon="images/icons/file-pdf.svg" fileName="Eerste persfoto (PDF)" fileDownloadLink="/files/persfoto-1.pdf" />
                            </Col>

                            <Col xl={4}>
                                <FileButton fileIcon="images/icons/file-pdf.svg" fileName="Tweede persfoto (PDF)" fileDownloadLink="/files/persfoto-2.pdf" />
                            </Col>

                            <Col xl={4}>
                                <FileButton fileIcon="images/icons/file-pdf.svg" fileName="Derde persfoto (PDF)" fileDownloadLink="/files/persfoto-3.pdf" />
                            </Col>

                        </Row>

                        <h2 css={h2Styling}>Reviews</h2>
                        <Row>
                            <Col xl={4}>
                                <FileButton fileIcon="images/icons/file-words.svg" fileName="Review Tiny Wooden Gods, geschreven door Guido Kees" fileDownloadLink="/files/review-tiny-wooden-gods-guido-kees.docx" />
                            </Col>

                        </Row>
                        
                        <Container fluid={false} css={pageStyling}>
                            <h1 css={h2Styling}>Artikels</h1>
                            <ManualGrid cols={3} gridItemContainerStyling={(t: Theme) => css`vertical-align: top;`}>
                                {articlesRender}
                            </ManualGrid>
                        </Container>

                    </Container>

                    <div ref={this.scrollMenuCollorRef}>
                        <Container css={(theme: Theme) => css`margin-top: 120px;`}>
                            <h1>Foto's</h1>
                        </Container>
                        <ImageSlideShow images={[{ imageId: '1', imagePath: 'images/fotos/test_foto.jpg' }, { imageId: '2', imagePath: 'images/fotos/originalHomeImage.png' }]} />
                    </div>
                </Page>
            </ThemeProvider>

        );
    }
}

export default Media;
