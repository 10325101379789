import Theme from 'orbiter-web-components/src/components/Theme';

export const ProjectTheme: Theme = {
    font:{
        fontSize:{
            default: '18px',
            h1: '40px',
            h2: '30px',
        },
        lineHeight:{
            default: '22px',
            h1: '50px',
            h2: '35px',
        },
        fontFamily:{
            default: 'Libre Franklin',
            h1: 'Libre Baskerville',
            h2: 'Libre Baskerville',
        },
    },
    colors: {
        primaryBackground: 'white',
        secondaryBackground: 'lightgray',
        primaryText: '#383838',
        secondaryText: 'white',
        highlightText: '#FFCC01',
        highlightBackground: '#5B5B5B',
    },
    margin: {
        extraSmallMargin: '5px',
        smallMargin: '10px',
        mediumMargin: '20px',
        largeMargin: '40px',
        extraLargeMargin: '400px',
    },
    padding: {
        extraSmallPadding: '5px',
        smallPadding: '15px',
        mediumPadding: '30px',
        largePadding: '150px',
        extraLargePadding: '400px',
    },
    mobileWidth: {
        maxWidth: '850px',
        minWidth: '851px',
    },
    button: {
        fontWeight: '400',
        fontSize: '1rem',
        padding: '0.375rem 1rem',
        backgroundColor: 'transparent',
        hoverBackgroundColor: 'transparent',
        activeBackgroundColor: 'transparent',
        borderColor: '#383838',
        hoverBorderColor: '#383838',
        activeBorderColor: '#383838',
        textColor: '#383838',
        border: '2px solid',
        borderRadius: '0',
        transition: 'color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
    },
    input: {
        fontWeight: '400',
        fontSize: '1rem',
        padding: '0.375rem 0.75rem',
        backgroundColor: 'white',
        hoverBackgroundColor: 'white',
        activeBackgroundColor: 'white',
        borderColor: '#FFCC00',
        hoverBorderColor: '#ecbd00',
        activeBorderColor: '#ecbd00',
        textColor: '#212529',
        border: '1px solid transparent',
        borderRadius: '0.25rem',
        transition: 'color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
    },
    radio: {
        backgroundColor: 'white',
        hoverBackgroundColor: 'white',
        checkedBackgroundColor: 'white',
        textColor: '#212529',
        hoverTextColor: '#212529',
        activeTextColor: '#212529',
    }

};