/** @jsx jsx */

import React from 'react';
import { jsx, css } from '@emotion/core';
import Page from 'orbiter-web-components/src/components/page/Page';
import Theme, { applyTheme } from 'orbiter-web-components/src/components/Theme';
import LeftCard from 'orbiter-web-components/src/components/card/LeftCard';
import { ThemeProvider } from 'emotion-theming';
import { ProjectTheme } from '../Theme';
import TwoSectionedFullScreenImage from 'orbiter-web-components/src/components/fullScreenImage/TwoSectionedFullScreenImage';
import AnimatedButton from 'orbiter-web-components/src/components/button/AnimatedButton';
import Footer from 'orbiter-web-components/src/components/footer/Footer';
import TopBar from '../components/TopBar';
import Fade from 'react-reveal/Fade';

export const defaultStyling = (theme: Theme) => {
    return css`
        color: ${applyTheme(theme, (t: Theme) => t.colors.primaryText)};
        line-height: ${applyTheme(theme, (t: Theme) => t.font.lineHeight.default)};
        font-family: ${applyTheme(theme, (t: Theme) => t.font.fontFamily.default)};
        font-size: ${applyTheme(theme, (t: Theme) => t.font.fontSize.default)};
    `;
};

export const homePageCardStyling = (theme: Theme) => {
    return css`
        border: none !important;
        margin: 0 !important;
        // height: 100vh; 
        background-color: transparent !important;
    `;
};

export const noPaddingStyling = (theme: Theme) => {
    return css`
        padding: 0 !important;
    `;
};

export const contentContainerStyling = (theme: Theme) => {
    return css`
        text-align: left;
        margin-left: 20%;
        margin-right: 5%;
        @media (max-width: ${applyTheme(theme, (t: Theme) => t.mobileWidth.minWidth)} )  {
            text-align: left;
            margin-left: 0;
            margin-right: 0;
        }

        @media (max-width: 1200px )  {
            text-align: left;
            margin-left: 10%;
            margin-right: 10%;
        }
    `;
};

export const CDStyling = (theme: Theme) => {
    return css`
        margin: 0 auto; 
        @media (max-width: ${applyTheme(theme, (t: Theme) => t.mobileWidth.minWidth)} )  {
            margin-top: 100px;
        }
    `;
};

class MijnWerk extends React.Component<any, any> {

    constructor(a: any) {
        super(a);
        this.state = {
        };
    }

    public render() {
        return (
            <ThemeProvider theme={ProjectTheme}>
                    <Page menu={<TopBar/>}
                        pageStyling={defaultStyling}
                        footer={<Footer/>}>
    
                        <TwoSectionedFullScreenImage
                            leftUnder={true}
                            leftSide={
                                <Fade duration={1500} left={true} delay={200}>
                                    <LeftCard contentContainerStyle={contentContainerStyling} verticalAlignContentStyle={noPaddingStyling} cardStyle={homePageCardStyling} title="Tiny Wooden Gods">
                                        <p>
                                        “The recording Cedric Honings has made of Tiny Wooden Gods not only reveal a virtuoso in the making but an artist of deep understanding, broad vision and deep courage.”
                                        <br/> <br/>- Roland Chadwick
                                        </p>
                                        <AnimatedButton href="https://www.amazon.com/Tiny-Wooden-Gods-Cedric-Honings/dp/B06XZJ2GC6/ref=sr_1_1?ie=UTF8&qid=1492539477&sr=8-1&keywords=Cedric+honings">Amazon</AnimatedButton>
                                        <AnimatedButton href="https://geo.itunes.apple.com/be/album/tiny-wooden-gods/id1222327339?mt=1&app=music">iTunes </AnimatedButton>
                                        <AnimatedButton href="https://open.spotify.com/album/2qUdTL7tMMXgnm6lzV9HGD?si=V0FLIyhWRqy1lQiuUiGjBQ">Spotify</AnimatedButton>
                                        <AnimatedButton href="https://bergmann-edition.com/products/chadwick-tiny-wooden-gods"> Bestel partituren </AnimatedButton>
                                    </LeftCard>
                                </Fade>
                            }
                            rightSide={
                                <Fade duration={1500} right={true}>
                                    <LeftCard imageSrc="images/fotos/cdfoto.jpg" ImageColSize={10} imageContainerStyle={CDStyling} cardStyle={homePageCardStyling}/>
                                </Fade>
                            }
                            leftRatio={0.65}
                        />
                        <TwoSectionedFullScreenImage
                            leftUnder={false}
                            leftSide={
                                <Fade duration={1500} left={true}>
                                    <LeftCard imageSrc="images/fotos/song-of-hope.jpg" ImageColSize={10} imageContainerStyle={CDStyling} cardStyle={homePageCardStyling}/>
                                </Fade>
                            }
                            rightSide={
                                <Fade duration={1500} right={true} delay={200}>
                                    <LeftCard contentContainerStyle={contentContainerStyling} verticalAlignContentStyle={noPaddingStyling} cardStyle={homePageCardStyling} title="Song of Hope">
                                        <p>
                                            "Song of Hope is all about the universal thoughts of hope and solidarity. Kevin Houben took inspiration from the poem ´Hope´ of the American poet Emily Dickinson (1830-1886)."
                                            <br/> <br/>- Kevin Houben
                                        </p>
                                        <AnimatedButton href="https://www.youtube.com/watch?v=-2L95IMNj_Q">YouTube</AnimatedButton>
                                        <AnimatedButton href="https://www.kevinhouben.be/product-page/song-of-hope-guitar"> Bestel partituren en CD </AnimatedButton>
                                    </LeftCard>
                                </Fade>
                            }
                            leftRatio={0.35}
                        />
                    </Page>
            </ThemeProvider>

        );
    }
}

export default MijnWerk;
