/** @jsx jsx */
import * as React from 'react';
import { jsx, css, SerializedStyles, Interpolation } from '@emotion/core';
import Theme, { applyTheme } from 'orbiter-web-components/src/components/Theme';
import Button from 'orbiter-web-components/src/components/button/Button';
import { Image } from 'react-bootstrap';
import { buttonStyling, innerButtonStyling, imageContainerStyling, inlineStyling, textContainerStyling } from './MainButtonStyling';

export interface ContactButtonProps {
    linkImage: string;
    visualContactInfo: string;
    contactLink: string;

    slideShowStyle?: (theme: Theme) => Interpolation;
    titleStyle?: (theme: Theme) => Interpolation;
}

export default class ContactButton extends React.Component<ContactButtonProps, any> {
    constructor(props: Readonly<ContactButtonProps>) {
        super(props);

    } 

    public render() {
        return (
            <Button buttonStyle={buttonStyling} href={this.props.contactLink}>
                <div css={innerButtonStyling}>
                    <div css={imageContainerStyling}>
                        <Image css={inlineStyling} src={this.props.linkImage}/>
                    </div>
                    <div css={textContainerStyling}>
                        <p css={inlineStyling}>{this.props.visualContactInfo}</p>
                    </div>
                </div>
            </Button>
        );
    }
}