/** @jsx jsx */

import React from 'react';
import { jsx, css } from '@emotion/core';
import DoubleMenu from 'orbiter-web-components/src/components/topbar/DoubleMenu';
import Theme, { applyTheme } from 'orbiter-web-components/src/components/Theme';

export const topBarLinkStyling = (theme: Theme) => {
    return css`
        display: inline;
        padding: 5px;
        text-transform: uppercase;
        color: ${applyTheme(theme, (t: Theme) => t.colors.primaryText)};
    `;
};

export const menuBarStyling = (theme: Theme) => {
    return css`
        background-color: transparent;
    `;
};

export const menuBarContainerStyling = (theme: Theme) => {
    return css`
        margin-bottom: -100px;
    `;
};

export const mobileMenuStyling = (theme: Theme) => {
    return css`
        text-align: center;
    `;
};

export const MobileMenuLinkStyling = (theme: Theme) => {
    return css`
        margin-bottom: 25px;
    `;
};

export function getTopBarLinks() {
    return (
        [
            <div key="toplink0.0" css={topBarLinkStyling}>
                <a key="toplink0" href="https://www.youtube.com/channel/UCvdN1yKowzjYUUcIlt-Rxjg/featured"><img css={topBarLinkStyling} src="images/icons/logo-youtube.svg" /></a>
                <a key="toplink1" href="https://www.instagram.com/cedrichoningsguitar/?hl=nl"><img css={topBarLinkStyling} src="images/icons/logo-instagram.svg" /></a>
                <a key="toplink2" href="https://www.facebook.com/CedricHoningsGuitarist/"><img css={topBarLinkStyling} src="images/icons/logo-facebook.svg" /></a>
            </div>,
            <a key="toplink3" href="/" ><p css={topBarLinkStyling}>Home</p></a>,
            <a key="toplink4" href="/mijn-werk" ><p css={topBarLinkStyling}>Mijn Werk</p></a>,
            <a key="toplink5" href="/kalender" ><p css={topBarLinkStyling}>Kalender</p></a>,
            <a key="toplink6" href="/media" ><p css={topBarLinkStyling}>Media</p></a>,
            <a key="toplink7" href="/biografie" ><p css={topBarLinkStyling}>Biografie</p></a>
        ]
    );
}

export default class TopBar extends React.Component<any, any> {

    public render() {
        return (
            <DoubleMenu linkStyling={MobileMenuLinkStyling} mobileLinkMenuStyling={mobileMenuStyling} height="100px" menuIconPath="images/icons/navigation-drawer.svg" imagePath="images/icons/Logo.svg" imageLinkPath="/" topBarStyling={menuBarStyling} topBarLinkSectionStyling={(theme: Theme) =>css`margin-right: ${applyTheme(theme, (t: Theme) => t.margin.extraSmallMargin)}`} containerStyling={menuBarContainerStyling}>
                {getTopBarLinks()}
            </DoubleMenu>
        );
    }
}